<template>
  <nav :class="`nav navbar navbar-expand-xl bg-primary navbar-light iq-navbar  ${headerNavbar} ${navbarHide.join('')}`" style="height: 84px; position: fixed; top: 0; width: 100%; z-index: 1000">
    <div class="container-fluid">
      <slot></slot>
      <div class="mobile-visible" style="margin-right: 3rem">
        <ul class="navbar-nav align-items-center">
          <li class="nav-item">
            <div class="dropdown">
              <span role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <font-awesome-icon :icon="['fas', 'bars']" size="xl" class="text-white" />
              </span>
              <ul class="dropdown-menu dropdown-menu-end bg-primary mt-5 custom-dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li class="py-2 px-3">
                  <a class="dropdown-item text-white d-flex justify-content-between align-items-center px-2" style="cursor: pointer" @click="scrollToElement('#sobre-nos')">
                    Sobre nós
                    <font-awesome-icon :icon="['fas', 'chevron-right']" class="text-white" />
                  </a>
                </li>
                <li class="py-2 px-3">
                  <a class="dropdown-item text-white d-flex justify-content-between align-items-center px-2" style="cursor: pointer" @click="scrollToElement('#areas-atuacao')">
                    Áreas de atuação
                    <font-awesome-icon :icon="['fas', 'chevron-right']" class="text-white" />
                  </a>
                </li>
                <li class="py-2 px-3">
                  <a class="dropdown-item text-white d-flex justify-content-between align-items-center px-2" style="cursor: pointer" @click="scrollToElement('#quem-somos')">
                    Quem Somos?
                    <font-awesome-icon :icon="['fas', 'chevron-right']" class="text-white" />
                  </a>
                </li>
                <li class="py-2 px-3">
                  <a class="dropdown-item text-white d-flex justify-content-between align-items-center px-2" style="cursor: pointer" @click="scrollToElement('#escritorio')">
                    Escritório
                    <font-awesome-icon :icon="['fas', 'chevron-right']" class="text-white" />
                  </a>
                </li>
                <li class="py-2 px-3">
                  <a class="dropdown-item text-white d-flex justify-content-between align-items-center px-2" style="cursor: pointer" @click="scrollToElement('#contatos')">
                    Contatos
                    <font-awesome-icon :icon="['fas', 'chevron-right']" class="text-white" />
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { computed, ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
export default {
  components: {},
  setup(props, { emit }) {
    const store = useStore()
    const headerNavbar = computed(() => store.getters['setting/header_navbar'])
    const fontSize = computed(() => store.getters['setting/theme_font_size'])
    const fullScreen = ref(false)
    const isHidden = ref(false)

    const scrollToElement = (selector) => {
      const element = document.querySelector(selector)
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 84, // Altura do seu navbar
          behavior: 'smooth' // Role suavemente
        })
      }
    }

    const btnClose = ref(null)
    const close = () => {
      btnClose.value.click()
    }

    const openFullScreen = () => {
      if (fullScreen.value) {
        fullScreen.value = false
        document.exitFullscreen()
      } else {
        fullScreen.value = true
        document.documentElement.requestFullscreen()
      }
    }

    const onscroll = () => {
      const yOffset = document.documentElement.scrollTop
      const navbar = document.querySelector('.navs-sticky')
      if (navbar !== null) {
        if (yOffset >= 100) {
          navbar.classList.add('menu-sticky')
        } else {
          navbar.classList.remove('menu-sticky')
        }
      }
    }

    const navbarHide = computed(() => store.getters['setting/navbar_show'])

    const carts = computed(() => store.getters.carts)

    onMounted(() => {
      window.addEventListener('scroll', onscroll())
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onscroll())
    })
    return {
      btnClose,
      headerNavbar,
      openFullScreen,
      close,
      fontSize,
      fullScreen,
      isHidden,
      carts,
      navbarHide,
      emit,
      scrollToElement
    }
  },
  props: {
    fullsidebar: { type: Boolean, default: false }
  }
}
</script>

<style scoped>
.custom-dropdown-menu {
  border-radius: 8px;
  width: 300px;
}

@media (max-width: 767px) {
  .mobile-visible {
    margin-right: 1.1rem !important;
  }
}
</style>
