<template>
  <!--Logo start-->
  <div class="logo-main">
    <div class="logo-normal">
      <img :src="logo" :class="`${color ? 'text-primary' : ''}`" style="width: 80px" />
    </div>
    <div class="logo-mini">
      <img :src="icon" :class="`${color ? 'text-primary' : ''}`" style="width: 80px" />
    </div>
  </div>
  <!--Logo End-->
</template>

<script>
import logo from '@/assets/images/kf-advogados/logos/logo_navbar.svg'
import logoBlue from '@/assets/images/chazit/icon-blue.png'
export default {
  props: {
    color: { type: Boolean, default: false },
    darkBackgroud: { type: Boolean, default: true }
  },
  data() {
    return {
      logo: this.darkBackgroud ? logo : logoBlue
    }
  }
}
</script>
